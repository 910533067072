import request from "../../assets/js/Request.js";

// 获取打分列表
export const getScoringRule = (params) => {
  return request.post("getScoringRule", params);
};
// 获取预览打分项目
export const getScoringItems = (params) => {
  return request.post("getScoringItems", params);
};
// 获取详细打分项目
export const scoringRule = (params) => {
  return request.post("scoringRule", params);
};
// 打分提交
export const judgeSetOpusScore = (params) => {
  return request.post("judgeSetOpusScore", params);
};