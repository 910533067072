import request from "../../assets/js/Request.js";

// 获取评委信息
export const getJudgesInfo = (params) => {
  return request.post("getJudgesInfo", params);
};
// 编辑评委信息
export const editJudgesInfo = (params) => {
  return request.post("editJudgesInfo", params);
};
// 普通评委获取作品数量
export const getOpusCount = (params) => {
  return request.post("getOpusCount", params);
};
// 专家评委获取作品数量
export const getOpusCountB = (params) => {
  return request.post("getOpusCountB", params);
};
// 普通评委获取作品详情
export const getOpusOne = (params) => {
  return request.post("getOpusOne", params);
};
// 专家评委获取作品列表
export const getOpusOneB = (params) => {
  return request.post("getOpusOneB", params);
};
// 普通评委获取作品列表
export const judgesGetOpusLists = (params) => {
  return request.post("judgesGetOpusLists", params);
};
// 专家评委获取作品列表
export const getOpusLists = (params) => {
  return request.post("judgesGetOpusListsB", params);
};