<template>
  <div class="videoDetails">
    <div class="bread">
      <span @click="goback">全部作品</span>
      <img src="../../assets/image/pingwei/left.png" />
      <span class="span2">作品打分</span>
    </div>
    <div class="content">
      <div
        :class="scoreList.length > 0 ? 'content-left' : 'content-left zhuanjia'"
      >
        <div class="csbh">参赛编号：{{ workInfo.bm_id }}</div>
        <div class="time">上传时间：{{ workInfo.addDate }}</div>

        <div class="video">
          <video
            v-if="workInfo.path"
            width="100%"
            height="100%"
            controls
            ref="video"
            style="object-fit: fill"
          >
            <source :src="aliyunURL + workInfo.path" type="video/mp4" />
            <source src="movie.ogg" type="video/ogg" />
            您的浏览器不支持Video标签。
          </video>
          <p v-else>视频链接已失效</p>
        </div>
        <!-- <div class="videoinfo">{{ workInfo.intro }}</div> -->
      </div>
      <div class="content-right" v-if="scoreList.length > 0">
        <div class="mytable">
          <h2>打分表</h2>
          <div class="boxs" v-for="item in scoreList" :key="item.id">
            <div class="title">
              <i
                :class="item.isShow ? 'open' : ''"
                @click="openChange(item.name)"
              ></i>
              <span>{{ item.name }} 【总分：{{ item.score }}分】</span>
              <span class="span2">
                <span v-if="item.totle > 0">已打分：{{ item.totle }}分</span>
                <input
                  name="Fruit"
                  type="checkbox"
                  :checked="!changeHandle(item)"
                  value=""
                  class="checkbox"
                />
              </span>
            </div>
            <transition name="el-zoom-in-top">
              <ul class="content-boxs" v-if="item.isShow">
                <li
                  class="content-box"
                  v-for="(item2, index) in item.children"
                  :key="index"
                >
                  <div class="content-boxtitle">
                    {{ index + 1 + "、" + item2.name }}
                    <span v-if="item2.type == 'number'">{{
                      "(0~" + item2.score + ")"
                    }}</span>
                  </div>
                  <div class="content-boxinput" v-if="item2.children">
                    <div
                      class="threebox"
                      v-for="(item3, index) in item2.children"
                      :key="index"
                    >
                      <div class="threetitle">
                        {{ item3.name
                        }}<span v-if="item3.type == 'number'">{{
                          "(0~" + item3.score + ")"
                        }}</span>
                      </div>
                      <div class="group" v-if="item3.type == 'radio'">
                        <el-radio-group v-model="item3.scored">
                          <el-radio
                            :label="item4"
                            v-for="item4 in item3.score"
                            :key="item4"
                            >{{ item4 }}分</el-radio
                          >
                        </el-radio-group>
                      </div>
                      <div v-else>
                        <el-input
                          v-model="item3.scored"
                          :max="item3.score"
                          min="0"
                          maxlength="4"
                          onkeyup="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d).*$/, '$1$2.$3').replace(/^\./g, '')"
                          @blur="onlyNonNegative($event, item3.score)"
                        ></el-input>
                      </div>
                    </div>
                  </div>
                  <div class="content-boxinput" v-else>
                    <div class="group" v-if="item2.type == 'radio'">
                      <el-radio-group v-model="item2.scored">
                        <el-radio
                          :label="item3"
                          v-for="item3 in item2.score"
                          :key="item3"
                          >{{ item3 }}分</el-radio
                        >
                      </el-radio-group>
                    </div>
                    <div v-else>
                      <el-input
                        v-model="item2.scored"
                        :max="item2.score"
                        min="0"
                        maxlength="4"
                        onkeyup="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d).*$/, '$1$2.$3').replace(/^\./g, '')"
                        @blur="onlyNonNegative($event, item2.score)"
                      ></el-input>
                    </div>
                  </div>
                </li>
              </ul>
            </transition>
          </div>
        </div>
        <div class="submitBtn">
          <el-button class="mybtn" @click="sumitScore()" v-if="IsB"
            >提交</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getOpusOne } from "../JudegeAPI/user";
import { getTokenJ } from "../../assets/js/Storage-J";
import { getScoringRule, judgeSetOpusScore } from "../JudegeAPI/Score";
export default {
  props: ["OpusCount"],
  data() {
    return {
      isCheck: false,
      radio: "",
      conBtn: false,
      aliyunURL: "https://vod.gyycbj.com/",
      workInfo: {}, //作品详情
      videoIsTrue: true, //视频链接是否失效
      //打分列表
      scoreList: [],
      score: [],
      IsB: true,
    };
  },
  created() {
    if (this.$route.params.id == undefined) {
      return this.$router.go(-1);
    }
    this.getWorkInfo(this.$route.params.id); //获取作品信息
    this.getScoreList(this.$route.params.id); //获取打分列表
  },
  mounted() {},
  watch: {
    radio(newVal) {
      if (newVal !== "") {
        this.conBtn = true;
      }
    },
  },
  computed: {
    changeHandle() {
      return (data) => {
        let Blood = false;
        data.children.forEach((item1) => {
          if (item1.children) {
            item1.children.forEach((item2) => {
              if (item2.scored === "") {
                Blood = true;
              }
            });
          } else {
            if (item1.scored === "") {
              Blood = true;
            }
          }
        });
        return Blood;
      };
    },
  },
  methods: {
    // 输入框失去焦点事件
    onlyNonNegative(e, max) {
      let value = e.target.value;
      // console.log(value, max);
      if (Number.isFinite(Number(value))) {
        if (Number(value) < 0 || Number(value) > Number(max)) {
          e.target.value = "";
          e.target.dispatchEvent(new Event("input"));
          return this.$message({
            showClose: true,
            message: "请输入正确的分值范围",
            type: "warning",
          });
        } else {
          // console.log('success');
        }
      } else {
        e.target.value = "";
        e.target.dispatchEvent(new Event("input"));
        return this.$message({
          showClose: true,
          message: "请输入正确的数据类型",
          type: "warning",
        });
      }
    },
    // 提交打分列表
    sumitScore() {
      let CloneArry = this.deepClone(this.scoreList);
      let myArry = [];
      let myTotal = 0;
      let myState = true;
      CloneArry.forEach((ele) => {
        delete ele.isShow;
        myArry.push(...ele.children);
      });
      myArry.forEach((item) => {
        if (item.children) {
          item.children.forEach((item2) => {
            if (item2.scored === "") {
              myState = false;
            } else {
              myTotal += Number(item2.scored);
            }
          });
        } else {
          if (item.scored === "") {
            myState = false;
          } else {
            myTotal += Number(item.scored);
          }
        }
      });
      if (!myState) {
        return this.$message.warning(
          "您还有未打分的题目，请确保每个题目都已打分"
        );
      }
      this.$confirm("您给出的总分是" + myTotal + "分, 是否确认打分?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.judgeSetOpusScoreHandel(JSON.stringify(CloneArry));
        })
        .catch(() => {});
    },
    // 提交打分请求
    async judgeSetOpusScoreHandel(data) {
      const { data: res } = await judgeSetOpusScore({
        j_id: getTokenJ().j_id,
        bm_id: this.workInfo.bm_id,
        score_data: data,
      });
      if (res.code !== "0000") {
        return this.$message(res.msg);
      }
      this.$message.success("打分成功");
      return this.$router.go(-1);
    },
    // 获取打分列表
    async getScoreList(workid) {
      const { data: res } = await getScoringRule({
        j_id: getTokenJ().j_id,
        bm_id: workid,
      });
      if (res.code !== "0000") {
        return this.$message(res.msg);
      }
      this.scoreList = res.data.rules;
    },
    // 折叠展开
    openChange(name) {
      this.scoreList = this.scoreList.map((item) => {
        if (item.name === name) {
          item.isShow = !item.isShow;
        }
        return item;
      });
    },
    // 获取普通评委作品详细信息
    async getWorkInfo(workid) {
      const { data: res } = await getOpusOne({
        j_id: getTokenJ().j_id,
        bm_id: workid,
      });
      if (res.code !== "0000") {
        return this.$message(res.msg);
      }
      this.workInfo = res.data;
      this.score = res.data.score;
    },
    // 返回上一级
    goback() {
      this.$router.go(-1);
    },
    // 深拷贝数据
    deepClone(obj) {
      let objClone = Array.isArray(obj) ? [] : {};
      if (obj && typeof obj === "object") {
        for (var key in obj) {
          if (obj.hasOwnProperty(key)) {
            //判断ojb子元素是否为对象，如果是，递归复制
            if (obj[key] && typeof obj[key] === "object") {
              objClone[key] = this.deepClone(obj[key]);
            } else {
              //如果不是，简单复制
              objClone[key] = obj[key];
            }
          }
        }
      }
      return objClone;
    },
  },
};
</script>

<style scoped lang="scss">
.videoDetails {
  .zhuanjia {
    flex: none !important;
    width: 800px;
  }
  .bread {
    background-color: #fff;
    padding: 0 30px;
    height: 70px;
    display: flex;
    align-items: center;
    span {
      cursor: pointer;
      display: block;
      color: #333333;
      font-size: 20px;
      font-weight: 600;
    }
    img {
      width: 42px;
      height: 42px;
      margin: 0 8px;
      margin-top: 3px;
    }
    .span2 {
      color: #5b2d9b;
    }
  }
  .content {
    margin-top: 17px;
    display: flex;
  }
  .content-left {
    flex: 2;
    margin-right: 30px;
    height: 100%;
    padding: 30px;
    background-color: #fff;
    font-size: 20px;
    color: #333333;
    .time {
      padding-bottom: 30px;
    }
    .csbh {
      color: #666;
      padding-bottom: 5px;
    }
    .video {
      width: 100%;
      background-color: #3330890d;
      height: 500px;
      margin-bottom: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        text-align: center;
        color: rgba(255, 0, 0, 0.884);
      }
    }
    .videoinfo::-webkit-scrollbar {
      display: none;
    }
    .videoinfo {
      height: 150px;
      font-size: 16px;
      overflow-y: scroll;
    }
  }
  .content-right {
    padding: 20px;
    flex: 1.1;
    box-sizing: content-box;
    background-color: #fff;
    // .mytable::-webkit-scrollbar {
    //   display: none;
    // }
    .mytable {
      font-size: 20px;
      height: 680px;
      overflow: scroll;
      overflow-x: hidden;
      h2 {
        padding: 25px 0;
        text-align: center;
      }
      .boxs {
        margin-bottom: 10px;
        .title {
          border: 1px solid #e5e5e5;
          border-radius: 5px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-bottom: none;
          background-color: #f7f8fa;
          font-size: 17px;
          i {
            display: block;
            width: 0;
            height: 0;
            border-top: 12px solid transparent;
            border-right: 12px solid transparent;
            border-bottom: 12px solid transparent;
            border-left: 12px solid #909399;
            cursor: pointer;
          }
          .open {
            margin-top: 12px;
            border-top: 12px solid #909399;
            border-right: 12px solid transparent;
            border-bottom: 12px solid transparent;
            border-left: 12px solid transparent;
          }
          padding: 0 20px;
          display: flex;
          height: 60px;
          border-bottom: 1px solid #e5e5e5;
          justify-content: space-between;
          align-items: center;
          .span2 {
            color: #5b2d9b;
            font-size: 17px;
            display: flex;
            align-items: center;
            .checkbox {
              width: 28px;
              height: 28px;
              margin-left: 15px;
              pointer-events: none;
            }
          }
        }
        .content-boxs {
          border: 1px solid #e5e5e5;
          border-radius: 5px;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          background-color: #f7f8fa;
          .content-box {
            padding: 20px;
            position: relative;
            .content-boxtitle {
              margin-bottom: 24px;
              font-size: 16px;
            }
            .content-boxinput {
              .threebox {
                margin-bottom: 15px;
              }
              .threetitle {
                font-size: 16px;
                padding-bottom: 5px;
              }
              .conbtn {
                position: absolute;
                right: 20px;
                cursor: pointer;
                bottom: 20px;
                width: 78px;
                height: 34px;
                color: #333333;
                background-color: #fff;
                text-align: center;
                line-height: 34px;
                border: 1px solid #e5e5e5;
              }
              .conbtn:active {
                background-color: #5b2d9b;
                color: #fff;
              }
              padding-left: 20px;
              ::v-deep .el-radio-group {
                display: flex;
              }
              ::v-deep .el-radio {
                display: flex;
                align-items: center;
              }
              ::v-deep .el-radio__inner {
                width: 24px;
                height: 24px;
              }
              ::v-deep .el-radio__input.is-checked .el-radio__inner {
                background-color: #fff;
                border-color: #5b2d9b;
              }
              ::v-deep .el-radio__inner::after {
                width: 12px;
                height: 12px;
                background-color: #5b2d9b;
              }
              ::v-deep .el-radio__label {
                color: #333333;
                font-size: 16px;
              }
              ::v-deep .is-checked {
                .el-radio__label {
                  color: #5b2d9b;
                }
              }
            }
          }
        }
      }
    }
    .submitBtn {
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      .mybtn {
        width: 170px;
        cursor: pointer;
        height: 50px;
        color: #fff;
        text-align: center;
        margin: 0 auto;
        font-size: 18px;
        background: #293f8e;
        margin-top: 10px;
      }
    }
  }
}
</style>
